* {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

body {
  color: #4d4d4d;
  font-size: 1.1rem;
}

.xxlarge-font {
  font-size: 2rem;
}

.xlarge-font {
  font-size: 1.8rem;
}

.large-font {
  font-size: 1.5rem;
}

.medium-font {
  font-size: 1.2rem;
}

.small-font {
  font-size: .9rem;
}

.typewriter-font {
  font-family: 'Roboto Mono', monospace;
}

.quote-font {
  font-family: 'Allan', cursive;
  font-size: 1.3rem;
  letter-spacing: 3px;
  color: #6142d6;
}

.underline {
  text-decoration: underline;
}

.highlight {
  background-color: #e6e6e6;
}

.bolder {
  font-weight: bolder;
}

.nowrap {
  white-space: nowrap;
}

.page__anchor {
  display: block;
  padding: 10px 0;
}

.margin-top20 {
 margin-top: 20px;
}

.margin-top10 {
  margin-top: 10px;
}

p {
  margin: 10px 0;
}

.block {
  display: block;
}

.post {
  border-radius: 10px;
  border: solid 2px #6142d6;
  box-shadow: 5px 5px 3px #bfbfbf;
  margin-bottom: 20px;
  background-color: #e7e2f8;
}

.post__border {
  border-radius: 10px;
  border: solid 2px #6142d6;
  box-shadow: 5px 5px 3px #bfbfbf;
  margin-bottom: 20px;
}

.post__header {
  color: #6142d6;
  display: grid;
  grid-template-columns: auto auto 1fr;
  border-bottom: solid 2px #6142d6;
  padding: 10px;
  align-items: center;
}

.post__navlink {
  padding: 10px;
}

.post__header__icon {
  font-size: 1.8rem;
  justify-self: start;
  color: inherit;
}

.post__header__type {
  justify-self: start;
  padding-left: 10px;
  color: inherit;
  font-weight: bolder;
}

.post__header__date {
  justify-self: end;
  color: inherit;
  font-weight: bolder;
}

.app-router {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  position: relative;
}

 .header__h1 {
  display: block;
  font-family: 'Julius Sans One', sans-serif;
  text-align: center;
  color: #c5ef35;
  background-color: #6142d6;
  letter-spacing: 3px;
  position: relative;
}

.header__h1--larger {
  letter-spacing: 10px;
  padding-bottom: 10px;
}

.header__h1:first-child {
  padding-top: 10px;
}

.header__h1:nth-child(2) {
  padding-bottom: 10px;
}

.header__h1--border {
  border-top: solid 3px #c5ef35;
  padding: 13px 0;
}

.header__navbar {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #e7e2f8;
}

.menubar__navlink {
  white-space: nowrap;
  text-decoration: none;
  padding: 0 6px;
}

.menu-drawer--is-active {
  font-weight: bold;
  background-color: #eee;
}

.menu-drawer__navlink {
  padding: 10px 16px;
  white-space: nowrap;
  text-decoration: none;
  margin-bottom: 2px;
}

.menu-drawer__navlink:last-of-type {
  margin-bottom: 0;
}

.menu-drawer__navlink:hover {
  background-color: #eee;
  text-decoration: underline;
}

.page-title {
  font-weight: bold;
  color: blue;
  background-color: white;
  text-align: center;
  margin: 15px 0;
}

.page__blockquote {
  padding: 10px 20px;
  background-color: white;
  margin: 10px 0;
}

.footer {
  text-align: center;
  background-color: #6142d6;
  width: 100%;
  position: sticky;
  height: 48px;
  top: calc(100vh - 48px);
  display: grid;
  grid-template-rows: 48px;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
}

.footer__link {
  color: #c5ef35;
  text-decoration: none;
  padding: 0 10px;
}

.page-component {
  padding: 10px;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
}

.false-statement-page__h3 {
  color: #180d3f;
  padding: 25px 0 0 2px;
  font-weight: normal;
  font-size: 1rem;
}

.page__div {
  font-size: 1rem;
  text-align: left;
  font-weight: normal;
  color: #180d3f;
  padding: 15px;
  background-color: #e7e2f8;
  margin-bottom: 20px;
}

.page__link {
  padding: 0 10px;
}

.home-page__link {
  padding: 10px;
  text-decoration: none;
}

.home-page__cite {
  display: block;
  padding: 10px;
}

.page__cite {
  padding: 10px 0;
}

.faa-header {
  text-align: left;
  background-color: #fff;
}

.faa-list {
  padding: 0 20px 20px 40px;
}

.post-list {
  margin: 10px;
  background-color: #e6e6e6;
}

.post-list-item {
  display: inline-block;
  margin: 5px;
  font-size: .9rem;
}

#home-subheading1 {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 3px;
  padding-bottom: 0;
}

#home-subheading2 {
  margin-bottom: 10px;
  padding-top: 0px;
}

.image-div {
  background-color: gray;
  overflow-x: scroll;
}

/* styles for card */
.card-list {
  list-style-type: none;
}

.card {
  margin: 20px;
  border-radius: 10px;
  border: solid 2px #6142d6;
  box-shadow: 5px 5px 3px #bfbfbf;
}

.card-header {
  text-align: center;
  color: #fff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  padding: 10px;
  font-size: 1.2rem;
  background-color: #6142d6;
}

.card-summary {
  padding: 10px;
  text-align: center;
  font-size: 1rem;
  background-color: white;
  position: relative;
  z-index: 200;
  border-radius: 10px;
  color: #180c41;
}

.card-button {
  display: block;
  position: relative;
  margin: 16px auto;
  width: 80%;
  padding: 7px;
  border-radius: 10px;
  color: #6142d6;
  font-size: 1.1rem;
  letter-spacing: 3px;
  font-weight: bold;
  background-color: #f2f2f2;
}

.card-button:hover {
  cursor: pointer;
}

.card-button-icon {
  float: right;
  padding-right: 10px;
  font-size: 18px;
  z-index: 1000;
  color: #6142d6;
}

.card-details {
  padding: 0 15px;
  transition: height .5s;
  height: 40vh;
}

.card-details a {
  padding: 10px 0;
  display: block;
}

.card-details-hide {
  height: 0;
  opacity: 0;
}

.details-children * {
  color: #180c41;
}

.details-children {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  margin: 10px 0;
}

.details-children h3 {
  font-size: .95rem;
  margin: 10px 0;
}

.details-children h4 {
  font-size: .9rem;
  margin: 10px 0;
}

.details-children p {
  font-size: .85rem;
  margin: 10px 0;
}

.details-children li {
  padding: 0 10px;
}

.contact p {
  font-size: .95rem;
  margin: 10px 0;
}

#contact-subheader1 {
  padding: 15px 0 5px 0;
  font-size: 1.3rem;
}

#contact-subheader2 {
  padding: 0 0 15px 0;
  font-size: 1.4rem;
}

.contacts-container {
  margin: 0 20px 20px 20px;
}

.contacts-container h3 {
  color: #080416;
  padding: 10px 0;
  font-weight: normal;
}

.sups {
  list-style-type: none;
}

.sups a {
  padding: 0;
}

.sups-link {
  display: inline-block;
  width: 100%;
  font-size: 1.4rem;
  text-align: center;
  padding: 10px 0;
}

.points {
  padding: 15px 0;
  font-size: 1rem;
  font-weight: bold;
}

.points li {
  margin-left: 20px;
  font-weight: normal;
  padding-top: 9px;
}

.letter-container {
  background-color: #e7e2f8;
  margin: 0 15px;
  padding: 20px;
}

.letter-container h3 {
  padding-bottom: 30px;
  text-align: center;
  letter-spacing: 3px;
  font-size: 1.3rem;
  font-weight: lighter;
}

.letter {
  border: solid 1px gray;
  padding: 20px;
  background-color: white;
}

.bold {
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 2px;
  margin-right: 10px;
}

.back-button {
  height: 40px;
  width: 40px;
  font-size: 1.6rem;
  color: gray;
  background-color: white;
  border: 0;
}

.back-button:hover {
  cursor: pointer;
}

.policy-change {
  margin: 10px;
  padding: 10px;
}

.details-image {
  width: 650px;
  height: auto;
  border: solid 2px gray;
  margin: 10px;
}

/********** Grand Jury *************/

.gj-h2 {
  font-weight: lighter;
}

.gj-h3 {
  font-weight: lighter;
  padding: 5px;
  margin: 20px 10px 10px 10px;
}

.gj-h4 {
  font-weight: lighter;
}

.gj-h5 {
  font-weight: bold;
  font-size: 1rem;
  margin:  30px 10px 10px 10px;
  padding: 10px 0;
  letter-spacing: 2px;
  background-color: #e6e6e6;
  text-align: center;
}

.gj-ul {
  list-style-type: upper-roman;
  margin: 10px;
}

.gj-li {
  margin: 15px 25px;
}

.gj-blockquote {
  padding: 5px;
  margin: 10px;
  font-family: serif;
  background-color: #e7e2f8;
}

.gj-p {
  margin: 15px;
  border: solid 2px #cccccc;
  border-radius: 10px;
  padding: 5px;
}

.redText {
  color: red;
}

.gj-div {
  margin: 10px;
}

.gj-new {
  color: #6142d6;
  padding: 0 3px;
  font-size: larger;
}

.menubar {
  padding: 6px 0;
  background-color: #e7e2f8;
  color: #6142d6;
  position: relative;
  display: grid;
  grid-template-columns: auto auto 1fr auto auto auto;
  align-items: end;
  justify-items: end;
}

.menubar a {
  color: #551a8b;
}

.menubar--is-active {
  font-weight: bold;
}

.menubar__navlink--align {
  justify-self: end;
}

.menubar__navlink--padding {
  padding-bottom: 2px;
}

.menu-drawer {
  border: solid 2px #6142b6;
  position: absolute;
  top: 0;
  left: -150vw;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: min-content;
  transition: left .5s ease;
  box-shadow: 5px 5px 3px #bfbfbf;
}

.menu-drawer--open {
  left: 0;
  z-index: 10;
}

.menu-drawer__icon {
  font-size: 1.1rem;
  margin: 10px;
  color: #551a8b;
  float: right;
  height: 23px;
}

.menu-drawer__button {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #551a8b;;
  cursor: pointer;
}

.header__div {
  position: relative;
}

.image-container {
  overflow-x: scroll;
}

.image-container__img {
  padding: 10px;
  height: auto;
  width: 750px;
}

.image-container__img--width {
  width: 100%;
}

.image-container__img--height {
  height: 100vh;
  width: auto;
}

figure {
  border: solid 2px gray;
  margin-bottom: 10px;
}

figcaption {
  background-color: gray;
  color: white;
  font-weight: bold;
  padding: 10px;
  text-align: center;
}

.figure__attribution {
  font-size: .8rem;
  padding: 10px;
  background-color: #e6e6e6;
  border-top: solid 2px gray;
}

.email-page__li {
  display: grid;
  grid-template-columns: auto 1fr;
  margin-bottom: 8px;
}

.email-page__li__checkbox {
  margin: 5px;
}

.email-page__li__label {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2;
}

.email-page__text-area {
  width: 100%;
  padding: 10px;
}

.email-subscribe-page__li {
  margin: 10px;
}


.form {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;
  font-size: 1.1rem;
}

.user-input__div {
  font-size: smaller;
  margin-top: 5px;
}

.user-input__label {
  display: block;
  margin-bottom: 5px;
  letter-spacing: 3px;
  color: #180d3f;
}

.user-input__input {
    border: 1px solid #bfbfbf;
    border-radius: 2px;
    font-size: smaller;
    padding: 2px 5px;
    width: 100%;
    background-color: #f2f2f2;
    color: black;
    margin-bottom: 4px;
}

.form__button {
  color: gray;
  background-color: white;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 6px;
  border: 2px solid gray;
  border-radius: 8px;
  padding: 11px;
  margin: 13px;
  box-shadow: 5px 5px 3px #bfbfbf;
}

.form__button--enabled {
  border: 2px solid blue;
  color: blue;
}

.form__button--width {
  width: calc(100% - 26px);
}

.no-visibility {
  position: absolute;
  left: -200vw;
}

.constraint__div {
  display: grid;
  align-items: start;
  grid-template-columns: 22px 1fr;
  margin-top: 6px;
  color: #555555d9;
}

.constraint__icon {
  justify-self: center;
  align-self: center;
  height: 14px;
}

.constraint__icon--blue {
  color: blue;
}

.progress-bar {
  height: 4px;
  display: inline-block;
  width: 0;
  background-color: blue;
}

.progress-bar-motion {
  transition: width 1.5s linear;
  width: 100%;
}

.preferences-page__div {
  display: grid;
  grid-template-columns: auto 1fr;
}
.radio-button {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 20px;
  border: 1px solid gray;
  background-color: blue;
}

.checkbox-label {
  align-self: center;
  margin-left: 10px;
}

.checkbox {
  display: grid;
    height: 40px;
    width: 40px;
    margin: 10px;
    place-items: center;
    background-color: blue;
    color: white;
    border-radius: 2px;
    box-shadow: 5px 5px 3px #bfbfbf;
}

.checkbox:hover {
  cursor: pointer;
}

.form__label {
  position: relative;
  top: -13px;
  background: white;
  padding: 3px 10px;
  justify-self: left;
  color: gray;
  font-size: smaller;
  letter-spacing: 1px;
}

.form__section {
  position: relative;
  margin-bottom: 20px;
  border: 2px solid gray;
  padding: 0 20px 35px 20px;
}

svg {
  height: 30px;
}

.fire-protection-page__h3 {
  padding: 10px 0 5px 0;
}

